import { lazy, Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Spinner } from 'reactstrap'
// ** Service Worker
import * as serviceWorker from './serviceWorker'
import { cfg } from "common";
import { EV } from 'enerx-shared';

if (!document.location.href.startsWith(cfg.appUrl)) {
    document.location.replace(`${cfg.appUrl}${document.location.pathname}${document.location.search}${document.location.hash}`);
}

// if (cfg.env != 'dev') {
//     ReactGA.initialize(cfg.googleAnalytic);
// }
const initializeSessionStorage = () => {
    const visitId = sessionStorage.getItem('visitId');
    if (!visitId) {
      const newVisitId = EV.utils.str.generateString();
      sessionStorage.setItem('visitId', newVisitId);
    }
  };
initializeSessionStorage();
const LazyForms = lazy(() => import('./forms/FormsApp'));
const LazyProposal = lazy(() => import('./forms/customers/res/ProposalPage'))
if (document.location.pathname?.startsWith('/forms/')) {
    ReactDOM.render((
        <Suspense fallback={<Spinner />}>
            <LazyForms />
        </Suspense>
    ),  document.getElementById('root'));   
}
else if (document.location.pathname?.startsWith('/proposal/')) {
    ReactDOM.render((
        <Suspense fallback={<Spinner />}>
            <LazyProposal />
        </Suspense>
    ),  document.getElementById('root'));   
}
else {
    import('./App').then(App => ReactDOM.render(<App.default/>, document.getElementById('root')));
}

  // If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
